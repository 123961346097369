import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Contacte1 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>Contacte</h2>
                </div>
                <div class='row  mbg  pb-5'>
                    {/* <div class='col-lg-6 col-md-11 px-3'> */}
                    <div class='row border border-dark '>
                        <p class='mp'>SC "Geotermal-AV" SRL</p>
                        <p class='mp'>telefon: (+373) 787 53 333</p>
                        <p class='mp'><a href='mailto:info@geosport.md'>e-mail: info@geosport.md</a></p>
                        <p class='mp'>Adresa: mun. Chisinau, str.Alba Iulia 190/1</p>
                        {/* </div> */}
                    </div>
                </div>
            </Container >
        );
    }
}

export default Contacte1;