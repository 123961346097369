import React, { Component } from 'react';

class MapGoogle extends Component {
    render() {
        return (
            <>
                <div><iframe title='map' width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Chisinau,%20aba%20iulia%20190/1+(Geotermal)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
            </>
        );
    }
}

export default MapGoogle;