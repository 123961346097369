import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../Pages/Home';
import InstalareIarba from '../Pages/InstalareIarba';
import InstalareTartan from '../Pages/InstalareTartan';
import Proiectare from '../Pages/Proiectare';
import Echipament from '../Pages/Echipament';
import Contacte from '../Pages/Contacte';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../img/logo.webp';
import "@fontsource/poppins/300.css";

function BasicExample() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="lg" className="bg-my text-center fixed-top">
                <Container  >
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            height="80"
                            width="80"
                            className="d-inline-block align-center"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mlink m-auto ">
                            <Nav.Link eventKey='1' class="m-auto px-4 my-3 " as={Link} to="/">Home</Nav.Link>
                            <NavDropdown title="Amenajarea terenuri" id="basic-nav-dropdown" className="me-auto mlink m-auto">
                                <NavDropdown.Item eventKey='2' as={Link} to="/InstalareIarba">Instalare Iarba artificiala</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='3' as={Link} to="/InstalareTartan">Acoperire din cauciuc</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey='4' class="mp m-auto px-4 my-3 " as={Link} to="/Proiectare">Servicii de Proiectare</Nav.Link>
                            <Nav.Link eventKey='5' class="mp m-auto px-4 my-3 " as={Link} to="/Echipament">Echipament sportiv</Nav.Link>

                            <Nav.Link eventKey='6' class="mp m-auto px-4 my-3" as={Link} to="/Contacte">Contacte</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div class='row mlink'>
                        <div class='d-none d-xl-block col-xl-12 '>
                            <p>(+373) 799 39 333</p>
                            <a href="mailto:info@geosport.md">info@geosport.md</a>
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/InstalareIarba' element={<InstalareIarba />} />
                <Route path='/InstalareTartan' element={<InstalareTartan />} />
                <Route path='/Proiectare' element={<Proiectare />} />
                <Route path='/Echipament' element={<Echipament />} />
                <Route path='/Contacte' element={<Contacte />} />
            </Routes>
        </Router>

    );
}

export default BasicExample;